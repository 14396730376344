define('ig-ember-app-measurement/services/app-measurement', ['exports', 'app-measurement'], function (exports, _appMeasurement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var linkTypes = {
    fileDownload: 'd',
    exitLink: 'e',
    customLink: 'o'
  };

  var TRACKING_EVENTS = {
    DEMO_LOGIN: 'event62',
    LOGIN: 'event5',
    DEAL_SUBMITTED: 'event53',
    DEAL_CONFIRMED: 'event50',
    DEAL_REJECTED: 'event52',
    DEAL_PARTIALLY_FILLED: 'event51',
    PAGE_VIEW: 'event1',
    CONTENT_VIEW: 'event93',
    CONTENT_DOWNLOAD: 'event34'
  };

  var VIDEO_EVENTS = {
    play: 'event15',
    firstQuartile: 'event80',
    secondQuartile: 'event81',
    thirdQuartile: 'event82',
    end: 'event18'
  };

  exports.default = Ember.Service.extend({
    appMeasurement: Ember.computed /* istanbul ignore next */(function () {
      return _appMeasurement.default;
    }),
    config: Ember.computed /* istanbul ignore next */(function () {
      var owner = Ember.getOwner(this);
      var config = owner && owner.resolveRegistration('config:environment');
      return config.APP.appMeasurement;
    }),

    visitor: Ember.computed(function () {
      var organisationId = Ember.get(this, 'config.trackingAccounts.organisationId');
      (false && !(organisationId) && Ember.assert('Configuration must be supplied in the config/environment.js file. Please ensure you have provided an organisation id', organisationId));

      var _Ember$get = Ember.get(this, 'config.trackingServer'),
          trackingServerSecure = _Ember$get.trackingServerSecure,
          trackingServer = _Ember$get.trackingServer;

      return Ember.get(this, 'appMeasurement').Visitor.getInstance(organisationId, {
        trackingServer: trackingServer,
        trackingServerSecure: trackingServerSecure
      });
    }),

    /**
     * @property clientId The id of the client that is currently using the application
     */
    clientId: undefined,

    /**
     * @property The accountId to use for tracking
     */
    account: undefined,

    /**
     * @property reportSuiteId to use for eVar22
     */
    reportSuiteId: undefined,

    trackingInstance: Ember.computed(function () {
      var account = Ember.get(this, 'account');
      return Ember.get(this, 'appMeasurement').getInstance(account);
    }),

    getInstance: function getInstance(properties) {
      var trackingClient = this.trackingClient();
      // Add any eVars/props provided into the tracking client
      return Object.assign(trackingClient, properties);
    },


    /**
     * @method trackingClient Gets a new instance of the tracking client
     */
    trackingClient: function trackingClient() {
      var _Ember$getProperties = Ember.getProperties(this, ['visitor', 'account', 'reportSuiteId']),
          visitor = _Ember$getProperties.visitor,
          account = _Ember$getProperties.account,
          reportSuiteId = _Ember$getProperties.reportSuiteId;

      var _Ember$getProperties2 = Ember.getProperties(this, ['config.pageName', 'config.trackingServer.trackingServer', 'config.trackingServer.trackingServerSecure']),
          pageName = _Ember$getProperties2['config.pageName'],
          trackingServer = _Ember$getProperties2['config.trackingServer.trackingServer'],
          trackingServerSecure = _Ember$getProperties2['config.trackingServer.trackingServerSecure'];

      // Ensure all cofiguration is available and an account has been provided


      var message = 'Configuration must be supplied in the config/environment.js file. Please ensure you have provided a ';
      (false && !(account) && Ember.assert('Account must be set with a valid account id before trying to track anything', account));
      (false && !(trackingServer) && Ember.assert(message + ' tracking server', trackingServer));
      (false && !(trackingServerSecure) && Ember.assert(message + ' secure server', trackingServerSecure));
      (false && !(pageName) && Ember.assert(message + ' pagename', pageName));


      var trackingClient = Ember.get(this, 'trackingInstance');

      // Purge the current instance. Normally done when changing page, but as ember is single page this needs to be done manually
      trackingClient.clearVars();

      // Set all of the variables required (can be overriden by all methods later)
      trackingClient.visitor = visitor;
      trackingClient.pageName = pageName;
      trackingClient.eVar22 = reportSuiteId ? reportSuiteId : account.split(',')[0];
      trackingClient.platform = pageName;
      trackingClient.eVar1 = trackingClient.prop9 = trackingClient.prop30 = 'D=pageName';
      trackingClient.trackingServerSecure = trackingServerSecure;
      trackingClient.trackingServer = trackingServer;

      var clientId = Ember.get(this, 'clientId');

      // If we have been provided a clientId then populate it
      if (clientId) {
        trackingClient.eVar61 = clientId;
      }

      return trackingClient;
    },


    /**
     *
     * @method trackPage Tracks the page passed in
     * @param {Object} [properties = {}] The eVars and props to send with this tracking
     */
    trackPage: function trackPage() {
      var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.trackEvent(TRACKING_EVENTS.PAGE_VIEW, properties);
    },


    /**
     * Track when content is viewed e.g. positions, history, market.
     *
     * example extra properties:
     * prop50 market epic
     * prop26 watchlist name
     * @param {String} contentType
     * @param {Object} properties
     */
    trackContentViewed: function trackContentViewed(contentType, properties) {
      this.trackEvent(TRACKING_EVENTS.CONTENT_VIEW, Object.assign({
        prop67: contentType
      }, properties));
    },


    /**
     * Track when content is downloaded e.g. Real Vision Reports.
     *
     * @param {String} provider
     * @param {String} fileId
     * @param {Object} properties
     */
    trackContentDownload: function trackContentDownload(provider, fileId, properties) {
      this.trackEvent(TRACKING_EVENTS.CONTENT_DOWNLOAD, Object.assign({
        eVar111: provider,
        eVar112: fileId
      }, properties));
    },


    /**
     * Track a video event
     *
     * @param {String} videoEvent
     * @param {String} videoName
     * @param {String} videoUrl
     * @param {String} playerType
     * @param {Object} properties
     */
    trackVideoEvent: function trackVideoEvent(videoEvent, videoName, videoUrl, category, playerType, properties) {
      this.trackEvent(VIDEO_EVENTS[videoEvent], Object.assign({
        eVar45: playerType,
        eVar30: videoName,
        eVar113: category,
        prop30: videoUrl
      }, properties));
    },


    /**
     * @method trackEvent Track an event. These are specified by the tracking guys, and must be in their list of
     * permitted values
     * @param {String} event The name of the event we wish to track e.g. 'event62' (Demo login)
     * @param {Object} [properties = {}] The eVars and props to send with this tracking
     */
    trackEvent: function trackEvent(event) {
      var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var instance = this.getInstance(properties);
      instance.events = event;
      instance.track();
    },


    /**
     * Track when a deal/order is submitted.
     * @param  {Ticket} ticket
     * @param  {String} ticketType e.g. OPEN_ORDER, OPEN_POSITION
     * @param  {Object} properties properties to override on the tracking object
     */
    trackDealSubmitted: function trackDealSubmitted(ticket, ticketType, properties) {
      var ticketTrackingData = this._getTicketTrackingData(ticket, ticketType);
      this.trackEvent(TRACKING_EVENTS.DEAL_SUBMITTED, Object.assign(ticketTrackingData, properties));
    },


    /**
     * Track when a deal/order is confirmed.
     * @param  {Ticket} ticket
     * @param  {String} ticketType e.g. OPEN_ORDER, OPEN_POSITION
     * @param  {Object} properties properties to override on the tracking object
     * @param  {Boolean} isPartialFill true if the deal was partially filled
     */
    trackDealConfirmed: function trackDealConfirmed(ticket, ticketType, properties, isPartialFill) {
      var ticketTrackingData = this._getTicketTrackingData(ticket, ticketType);
      this.trackEvent(isPartialFill ? TRACKING_EVENTS.DEAL_PARTIALLY_FILLED : TRACKING_EVENTS.DEAL_CONFIRMED, Object.assign(ticketTrackingData, properties));
    },


    /**
     * Track when a deal/order is rejected.
     * @param  {Ticket} ticket
     * @param  {String} ticketType e.g. OPEN_ORDER, OPEN_POSITION
     * @param  {Object} properties properties to override on the tracking object
     */
    trackDealRejected: function trackDealRejected(ticket, ticketType, rejectionMessage, properties) {
      var ticketTrackingData = this._getTicketTrackingData(ticket, ticketType);
      if (rejectionMessage) {
        ticketTrackingData.prop65 = rejectionMessage;
      }
      this.trackEvent(TRACKING_EVENTS.DEAL_REJECTED, Object.assign(ticketTrackingData, properties));
    },


    /**
     * Maps tracking props to properties on the ticket.
     * @param  {Ticket} ticket
     * @param  {String} ticketType
     * @private
     * @return {Object}
     */
    _getTicketTrackingData: function _getTicketTrackingData(ticket, ticketType) {
      var ticketProps = {};
      if (ticket) {
        ticketProps = {
          prop61: ticket.direction,
          prop42: ticket.size,
          prop41: ticket.market ? ticket.market.instrumentType : null,
          prop55: ticket.currency ? ticket.currency.name : null,
          prop64: ticket.stopLevel,
          prop56: ticket.stopType,
          prop43: ticket.stopStep,
          prop54: ticket.limitLevel,
          prop57: ticket.orderType,
          prop63: ticket.orderLevel,
          prop50: ticket.market ? ticket.market.epic : ticket.epic
        };
        if (ticket.errors) {
          this._setTicketErrors(ticketProps, ticket.errors.snapshot());
        }
      }
      ticketProps.prop51 = ticketType;
      return ticketProps;
    },


    /**
     * Sets the rejection message property on the given ticket properties.
     * @param {Object} properties
     * @param {Object} errorsSnapshot
     */
    _setTicketErrors: function _setTicketErrors(properties, errorsSnapshot) {
      var errorsString = Object.values(errorsSnapshot).filter(function (e) {
        return (typeof e === 'undefined' ? 'undefined' : _typeof(e)) === 'object' && !!e;
      }).map(function (e) {
        return e.key;
      }).toString();
      properties.prop65 = errorsString;
    },


    /**
     * @method trackCustomLink Tracks link data that does not change the url, e.g. downloads or next buttons
     * @param {String} linkName An identifier for the link to be tracked
     * @param {Object} [properties = {}] The eVars and props to send with this tracking
     */
    trackCustomLink: function trackCustomLink(linkName) {
      var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var instance = this.getInstance(properties);

      // Adobe analytics only allows 100 characters for this
      (false && !(linkName && linkName.length <= 100 && linkName.length > 0) && Ember.assert('Link name must be between 1 and 100 characters long', linkName && linkName.length <= 100 && linkName.length > 0));

      // Pass true as the first parameter to prevent the 500ms delay that adobe analytics triggers when using this method

      instance.trackLink(true, linkTypes.customLink, linkName);
    }
  });
});