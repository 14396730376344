define("shared-components/templates/components/payment-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yQh9H1ZY",
    "block": "{\"symbols\":[\"@active\",\"&attrs\",\"@payment\",\"@icon\",\"@title\",\"@text\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"payment-overview \",[28,\"if\",[[23,0,[\"fixedWidth\"]],\"payment-overview--fixed\"],null],\" \",[28,\"if\",[[23,1,[]],\"payment-overview--active\"],null],\" row\"]]],[13,2],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"cell payment-overview_icon \",[28,\"or\",[[23,4,[]],\"payment-overview_icon--bank\"],null],\" \",[28,\"if\",[[23,3,[\"primary\"]],\"payment-overview_icon--primary\"],null]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell payment-overview_details\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"payment-overview_title \",[28,\"unless\",[[23,3,[\"verified\"]],\"payment-overview_title--unverified\"],null]]]],[8],[0,\"\\n      \"],[1,[28,\"titleize\",[[23,5,[]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row payment-overview_text\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"cell\"],[8],[1,[23,6,[]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,3,[\"expiryDate\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"cell cell--right\"],[8],[1,[28,\"t\",[\"wallet.exp\"],null],false],[0,\" (\"],[1,[24,[\"payment\",\"expiryDate\"]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/payment-overview.hbs"
    }
  });
  _exports.default = _default;
});