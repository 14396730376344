define("shared-components/templates/components/docupload-address-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4ortvzty",
    "block": "{\"symbols\":[\"@type\",\"@address\",\"@disagree\",\"@agree\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"card_text doc-upload_review-declaration\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"docUpload.address-explain\"],[[\"type\"],[[23,1,[]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"card_text doc-upload_review-address\"],[8],[0,\"\\n  \"],[1,[23,2,[]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"doc-upload_review-buttons\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn doc-upload_review-button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]]],null]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"no\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn doc-upload_review-button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,4,[]]],null]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"yes\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/docupload-address-check.hbs"
    }
  });
  _exports.default = _default;
});