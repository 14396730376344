define("shared-components/components/plaid-error", ["exports", "shared-components/templates/components/plaid-error"], function (_exports, _plaidError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _plaidError.default
  });
  _exports.default = _default;
});