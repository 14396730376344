(function() {
  function vendorModule() {
    'use strict';

    return {
      'default': self['idbKeyval'],
      __esModule: true,
    };
  }

  define('idb-keyval', [], vendorModule);
})();
