define("shared-components/utils/theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSelectedTheme = getSelectedTheme;
  _exports.getSystemTheme = getSystemTheme;
  _exports.getTheme = getTheme;
  _exports.setTheme = setTheme;
  function getTheme(window) {
    var selectedTheme = getSelectedTheme(window);
    if (selectedTheme === 'system') {
      return getSystemTheme(window);
    }
    return selectedTheme;
  }
  function getSelectedTheme(window) {
    return window.localStorage.theme || 'system';
  }
  function getSystemTheme(window) {
    if (!window.matchMedia) {
      return 'dark'; // return default value if there is no system setting for theme
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
  function setTheme(window, theme) {
    var themeColour = theme === 'system' ? getSystemTheme(window) : theme;
    if (window.localStorage) {
      window.localStorage.theme = theme;
    }
    window.document.querySelector('html').setAttribute('data-theme', themeColour);
    return theme;
  }
});