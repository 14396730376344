define("shared-components/templates/components/payments-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "guboiuAC",
    "block": "{\"symbols\":[\"@backAction\",\"@errorTitle\",\"@errorMessage\",\"@errorLinkAction\",\"@errorLinkText\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page card-holder payments-error\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"payments-error_close\"],[11,\"onclick\",[28,\"fn\",[[23,1,[]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"payments-error_icon\"],[8],[9],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"payments-error_title\"],[8],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"payments-error_message\"],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn--href payments-error_link\"],[11,\"onclick\",[28,\"fn\",[[23,4,[]]],null]],[8],[1,[23,5,[]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/payments-error.hbs"
    }
  });
  _exports.default = _default;
});