define("shared-components/templates/components/contact-us-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4irb9ixW",
    "block": "{\"symbols\":[\"@support\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"contact-us\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card card--conjoined\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_header\"],[8],[1,[28,\"t\",[\"profile.contact-us\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_body\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"card_text card_text--grey\"],[8],[1,[28,\"t\",[\"contact.introduction\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_body card_body--inline\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"card_text--bold\"],[8],[1,[28,\"t\",[\"contact.email\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card_data\"],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[23,1,[\"supportEmail\"]]]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[10,\"class\",\"card_data-value contact-us_email\"],[8],[1,[23,1,[\"supportEmail\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_body card_body--inline\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"card_text--bold\"],[8],[1,[28,\"t\",[\"contact.office\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"contact-us_address\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"contact-us_address-text\"],[8],[1,[23,1,[\"officeAddress\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/contact-us-card.hbs"
    }
  });
  _exports.default = _default;
});