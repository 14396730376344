define("shared-components/templates/components/lease-acceptance-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "v312Uxy0",
    "block": "{\"symbols\":[\"@title\",\"@showFooter\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"doc-upload-acceptance\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"doc-upload-acceptance_title\"],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"doc-upload-acceptance_list card card--conjoined\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[1,[28,\"t\",[\"docUpload.we-accept.lease-1\"],null],false],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[1,[28,\"t\",[\"docUpload.we-accept.lease-2\"],null],false],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[1,[28,\"t\",[\"docUpload.we-accept.lease-3\"],null],false],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[1,[28,\"t\",[\"docUpload.we-accept.lease-4\"],null],false],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[1,[28,\"t\",[\"docUpload.we-accept.lease-5\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"doc-upload-acceptance_footer\"],[8],[1,[28,\"t\",[\"docUpload.we-accept.lease-6\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/lease-acceptance-list.hbs"
    }
  });
  _exports.default = _default;
});