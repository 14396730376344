define('ismobilejs/initializers/is-mobile-service-injector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  /**
   * isMobile service injector
   *
   * Supports auto injecting the isMobile service app-wide.
   * Generated by the ember-is-mobile addon.
   */
  function initialize() {
    var application = arguments[1] || arguments[0];

    application.inject('controller', 'isMobile', 'service:isMobile');
    application.inject('component', 'isMobile', 'service:isMobile');
    application.inject('route', 'isMobile', 'service:isMobile');
    application.inject('view', 'isMobile', 'service:isMobile');
  }

  exports.default = {
    name: 'isMobile',
    initialize: initialize
  };
});