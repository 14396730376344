define("shared-components/templates/components/addresses-card-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DZ6hei7r",
    "block": "{\"symbols\":[\"@elevatePermissionsAndEdit\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"readOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"role\",\"button\"],[10,\"class\",\"profile_edit\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[]]],null]],[8],[1,[28,\"t\",[\"profile.edit\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"role\",\"button\"],[10,\"class\",\"profile_edit\"],[11,\"onclick\",[28,\"perform\",[[24,[\"submitIfValid\"]]],null]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"profile.save\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/addresses-card-toggle.hbs"
    }
  });
  _exports.default = _default;
});