define("shared-components/templates/components/card-deposit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uRIsmwiz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"iframe\",true],[11,\"class\",[29,[\"card card-payment \",[28,\"unless\",[[24,[\"paymentsUiLoaded\"]],\"card-payment--loading\"],null]]]],[8],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/card-deposit-card.hbs"
    }
  });
  _exports.default = _default;
});