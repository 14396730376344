define("shared-components/components/card-withdrawal-limits", ["exports", "shared-components/templates/components/card-withdrawal-limits"], function (_exports, _cardWithdrawalLimits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _cardWithdrawalLimits.default,
    tagName: ''
  });
  _exports.default = _default;
});