define("shared-components/templates/components/withdrawals-disabled-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E5lRjfQx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"withdrawals-disabled_warning-icon\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"fullMessageShown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"payments.card-withdrawals-disabled.message-long\"],[[\"htmlSafe\"],[true]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"payments.card-withdrawals-disabled.message-short\"],null],false],[9],[0,\"\\n\"],[0,\"  \"],[7,\"span\",false],[12,\"class\",\"withdrawals-disabled_see-more\"],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"fullMessageShown\"]]],null],true],null]]],[8],[1,[28,\"t\",[\"payments.card-withdrawals-disabled.see-more\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/withdrawals-disabled-message.hbs"
    }
  });
  _exports.default = _default;
});