define("shared-components/templates/components/proof-of-address-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4mJFsVMc",
    "block": "{\"symbols\":[\"&attrs\",\"@address\",\"@onWrongAddress\",\"@noDocumentsComponent\",\"@onSelectOption\",\"@secureText\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"card\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card_text doc-upload_intro\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"docUpload.address-details.intro\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card_body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"doc-upload_check-details\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"doc-upload_divider\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"card_sub-header\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"docUpload.address-details.address\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"card_text\"],[8],[0,\"\\n          \"],[1,[23,2,[]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",false],[12,\"class\",\"doc-upload_verify\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[23,3,[]]]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"docUpload.address-details.wrong\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"doc-upload_instructions\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"docUpload.upload-docs\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"doc-upload-options\",[],[[\"@noDocumentsComponent\",\"@onSelectOption\",\"@options\",\"@secureText\",\"@type\"],[[23,4,[]],[23,5,[]],[28,\"array\",[\"bill\",\"license\",\"lease\"],null],[23,6,[]],\"address-details\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,7]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/proof-of-address-selector.hbs"
    }
  });
  _exports.default = _default;
});