define("shared-components/templates/components/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zNr8JCm5",
    "block": "{\"symbols\":[\"@target\",\"@attachment\",\"@targetAttachment\",\"@constraints\",\"@targetOffset\",\"&attrs\",\"@onClickOutsideExcludedClass\",\"@onClickOutside\",\"&default\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[5,\"ember-tether\",[],[[\"@class\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@constraints\",\"@targetOffset\"],[[23,0,[\"class\"]],[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"tooltip\"],[13,6],[3,\"on-click-outside\",[[23,8,[]],[23,7,[]]]],[8],[0,\"\\n      \"],[14,9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/tooltip.hbs"
    }
  });
  _exports.default = _default;
});