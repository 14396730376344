define("shared-components/templates/components/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZSjGbUxg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card feedback\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card_header\"],[8],[1,[28,\"t\",[\"profile.feedback-title\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card_body\"],[8],[0,\"\\n    \"],[7,\"form\",false],[12,\"class\",\"feedback_form\"],[12,\"novalidate\",\"\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[23,0,[\"submitFeedback\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"card_text card_text--grey\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"feedback.introduction\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"textarea\",null,[[\"class\",\"oninput\",\"value\"],[\"feedback_textarea\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"feedbackMessage\"]]],null]],[[\"value\"],[\"target.value\"]]],[23,0,[\"feedbackMessage\"]]]]],false],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"btn btn--neutral feedback_btn\"],[11,\"value\",[28,\"t\",[\"feedback.submit\"],null]],[11,\"disabled\",[28,\"or\",[[28,\"get\",[[23,0,[]],\"submitFeedback.isRunning\"],null],[28,\"not\",[[23,0,[\"feedbackMessage\"]]],null]],null]],[10,\"type\",\"submit\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"feedback.submit\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/feedback.hbs"
    }
  });
  _exports.default = _default;
});