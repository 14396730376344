define("shared-components/templates/components/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pZzBxolo",
    "block": "{\"symbols\":[\"@size\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"flag \",[28,\"if\",[[23,1,[]],[28,\"concat\",[\"flag--\",[23,1,[]]],null]],null]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"flag_label\"],[8],[14,2],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/flag.hbs"
    }
  });
  _exports.default = _default;
});