define("shared-components/templates/components/payments-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V3qJuBeF",
    "block": "{\"symbols\":[\"@accountLink\",\"@title\",\"@requestDetails\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page card-holder payments-confirm\"],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"payments-confirm_close\",[23,1,[]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"payments-confirm_details\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"payments-confirm_icon\"],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"payments-confirm_heading\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"payments-confirm_text\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"payments-confirm_link\",[23,1,[]]]],{\"statements\":[[1,[28,\"t\",[\"payments.confirm.link\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/payments-confirm.hbs"
    }
  });
  _exports.default = _default;
});