define("ember-engines/-private/engine-ext", ["@embroider/macros/es-compat", "ember-engines/components/link-to-external"], function (_esCompat, _linkToExternal) {
  "use strict";

  Ember.Engine.reopen({
    buildRegistry: function buildRegistry() {
      var registry = this._super.apply(this, arguments);
      if (!(this instanceof Ember.Application)) {
        {
          var EngineScopedLinkComponent = (0, _esCompat.default)(require("ember-engines/components/link-to-component")).default;
          registry.register('component:link-to', EngineScopedLinkComponent);
        }
        registry.register('component:link-to-external', _linkToExternal.default);
      }
      return registry;
    }
  });
});