define("shared-components/components/payments-error", ["exports", "shared-components/templates/components/payments-error"], function (_exports, _paymentsError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _paymentsError.default,
    tagName: ''
  });
  _exports.default = _default;
});