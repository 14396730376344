define("shared-components/templates/components/ach-failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Yes40qMQ",
    "block": "{\"symbols\":[\"&default\",\"@showInstructions\",\"@supportEmail\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page card-holder wallet-confirm-failed\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"cell wallet-confirm-failed-icon\"],[8],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"card_header wallet-confirm-failed-header\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"wallet.bank.ach-failed\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_body grid\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"cell card_text\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"wallet.bank.ach-failed-explain\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn--neutral wallet-confirm-failed-btn\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,2,[]]]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"wallet.bank.ach-details\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"cell card_text wallet-confirm-failed-footer\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"wallet.bank.ach-contact\"],null],false],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[23,3,[]]]]],[10,\"class\",\"link\"],[8],[0,\"\\n            \"],[1,[23,3,[]],false],[0,\".\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/ach-failed.hbs"
    }
  });
  _exports.default = _default;
});