define("shared-components/templates/components/plaid-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Lj7V+PQj",
    "block": "{\"symbols\":[\"@depositWithCard\",\"@relaunchPlaid\",\"@title\",\"&default\",\"@addBankDetails\",\"@businessAccountWarning\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"plaid-error\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"plaid-error_title\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n  \"],[14,4],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"plaid-error_btn plaid-error_btn--plaid\"],[3,\"on\",[\"click\",[23,2,[]]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"wallet.bank.relaunch-plaid\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"plaid-error_btn plaid-error_btn--debit-card\"],[3,\"on\",[\"click\",[23,1,[]]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"wallet.bank.fund-with-debit-card\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"button\",false],[12,\"class\",\"plaid-error_btn plaid-error_btn--add-bank\"],[3,\"on\",[\"click\",[23,5,[]]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"if\",[[23,6,[]],\"wallet.bank.add-personal-bank-account\",\"wallet.bank.add-bank-details\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"sub\",true],[10,\"class\",\"plaid-error_disclaimer\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"if\",[[23,6,[]],\"wallet.bank.plaid-business-account-disclaimer\",\"wallet.bank.plaid-additional-information-disclaimer\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/plaid-error.hbs"
    }
  });
  _exports.default = _default;
});