define("shared-components/utils/card-payment-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorStates = _exports.GenericPaymentsError = void 0;
  var GenericPaymentsError = function GenericPaymentsError() {
    return {
      errorCode: 'GENERIC_ERROR',
      title: 'unexpected-error',
      showErrorScreen: true,
      linkText: 'payments.confirm.link'
    };
  };
  _exports.GenericPaymentsError = GenericPaymentsError;
  var errorStates = [{
    errorCode: 'ELEVATED_ACCESS_EXPIRED',
    title: 'auth0-reauthentication-required',
    showErrorScreen: true,
    linkText: 'payments.auth0-reauthentication-retry'
  },
  // {
  //   errorCode: 'AUTHENTICATION_REQUIRED',
  //   title: 'reauthentication-required',
  //   showErrorScreen: true,
  //   linkText: 'payments.auth0-reauthentication-retry',
  // },
  {
    errorCode: 'CARD_COUNTRY_RESTRICTED',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'PAYMENT_PROVIDER_ERROR',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'PAYMENT_PROVIDER_PROBLEM',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'CARD_NOT_VERIFIED',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'CARD_TERMINATED',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'CARD_EXPIRED',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'CARD_USED_TOO_RECENTLY',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: false
  }, {
    errorCode: 'BANK_DECLINED',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'MEMBER_NOT_ACTIVE',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'MEMBER_ON_HOLD',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'ADDRESS_CHECK_FAILED',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'REJECTED_BY_EXCHANGE',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'CONNECTION_ERROR',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'UNEXPECTED_ERROR',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'SERVICE_UNAVAILABLE',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'PAYMENTS_GENERIC_ERROR',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'PAYMENTS_ERROR_500',
    title: 'withdrawal-unsuccessful',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }, {
    errorCode: 'ERROR_500',
    title: 'unexpected-error',
    showErrorScreen: true,
    linkText: 'payments.confirm.link'
  }];
  _exports.errorStates = errorStates;
});