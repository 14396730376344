define("shared-components/templates/components/card-withdrawal-limits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ESs+QUbG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"withdrawal_card-limits-subheading\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.why-subheader\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"withdrawal_card-limits-text\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.why-text\"],null],false],[9],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"withdrawal_card-limits-subheading\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.what-subheader\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"withdrawal_card-limits-text\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.what-text\"],null],false],[9],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"withdrawal_card-limits-subheading\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.how-subheader\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"withdrawal_card-limits-text\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.how-text\"],null],false],[9],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"withdrawal_card-limits-subheading\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.how-much-subheader\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"withdrawal_card-limits-text\"],[8],[1,[28,\"t\",[\"payments.withdrawal-limits.how-much-text\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/card-withdrawal-limits.hbs"
    }
  });
  _exports.default = _default;
});