define("shared-components/templates/components/modal-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EvxBEMTO",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"modal-wormhole\"]],{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"ticket-mask \",[28,\"if\",[[24,[\"onClose\"]],\"ticket-mask--interactive\"],null]]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"hide\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"message\"],[13,1],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"      \"],[7,\"section\",true],[10,\"class\",\"confirm_heading\"],[8],[0,\"\\n        \"],[7,\"header\",true],[10,\"class\",\"card_header\"],[8],[0,\"\\n          \"],[1,[22,\"title\"],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/modal-box.hbs"
    }
  });
  _exports.default = _default;
});