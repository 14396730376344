define("shared-components/templates/components/switch-account-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cv599xXX",
    "block": "{\"symbols\":[\"( unused value )\",\"accountName\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card card--conjoined\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card_header\"],[8],[1,[28,\"t\",[\"my-account.switch-account\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card_body\"],[8],[0,\"\\n    \"],[7,\"form\",false],[3,\"on\",[\"submit\",[28,\"fn\",[[23,0,[\"switchAccount\"]]],null]]],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,0,[\"tokensByName\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"label\",true],[8],[0,\"\\n          \"],[5,\"material-input\",[[12,\"class\",\"switch-account_radio\"]],[[\"@hasValue\",\"@isFocused\"],[true,false]],{\"statements\":[[0,\"\\n            \"],[7,\"input\",false],[12,\"checked\",[28,\"eq\",[[23,0,[\"selectedName\"]],[23,2,[]]],null]],[12,\"class\",\"input radio\"],[12,\"name\",\"account\"],[12,\"value\",[23,2,[]]],[12,\"type\",\"radio\"],[3,\"on\",[\"change\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"selectedName\"]]],null],[23,2,[]]],null]]],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n              \"],[1,[23,2,[]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"      \"],[7,\"button\",true],[10,\"class\",\"btn btn--neutral switch-account_btn\"],[10,\"type\",\"submit\"],[8],[1,[28,\"t\",[\"my-account.switch-account\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/switch-account-card.hbs"
    }
  });
  _exports.default = _default;
});