define("shared-components/templates/components/statement-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tlSDQow7",
    "block": "{\"symbols\":[\"pdfDocument\",\"&default\",\"@fetchStatement\",\"@downloadStatement\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"statement\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_header\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"statement_title\"],[8],[1,[28,\"t\",[\"profile.statement\"],null],false],[9],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"statement_download btn btn--ghost\"],[12,\"disabled\",[28,\"not\",[[23,3,[\"last\",\"value\"]]],null]],[3,\"on\",[\"click\",[28,\"perform\",[[23,4,[]],[23,3,[\"last\",\"value\",\"data\"]]],null]]],[8],[1,[28,\"t\",[\"profile.download\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_text statement_introduction\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"statement.30-days\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card card--conjoined statement_document-container\"],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"app-loading\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not\",[[23,3,[\"last\",\"value\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"statement.not-loaded\"],null],false],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"with\",[[23,3,[\"last\",\"value\",\"pdfDocument\"]]],null,{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]],[28,\"task\",[[23,0,[\"renderPage\"]]],null]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/statement-card.hbs"
    }
  });
  _exports.default = _default;
});