define("shared-components/utils/filter-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterElements;
  var BLACKLISTED_ELEMENT_IDS = ['VretypeAccountNumberIBAN', 'confirmBankAccount'];
  function filterElements(model) {
    model.countryValidationElements = model.countryValidationElements.filter(function (element) {
      Ember.set(element, 'value', null);
      Ember.set(element, 'error', null);
      return !BLACKLISTED_ELEMENT_IDS.includes(element.elementId) && element.required && !element.notUsed;
    });
    return model;
  }
});