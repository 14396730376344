define("shared-components/templates/components/docupload-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qzbwVcUT",
    "block": "{\"symbols\":[\"@onNextDocument\",\"@description\",\"@refresh\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page card-holder doc-upload\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card card--conjoined\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card_body doc-upload_review--centered\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"doc-upload_review-success\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"doc-upload_review-bold\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"docUpload.thanks\"],[[\"type\"],[[23,2,[]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"card_text doc-upload_review-text\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"docUpload.review-confirmation\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"doc-upload_review-buttons doc-upload_review-done\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"button\",false],[12,\"class\",\"btn btn--neutral\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[]]],null]]],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"docUpload.next-document\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"button\",false],[12,\"class\",\"btn\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]]],null]]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"docUpload.back-to-account\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/docupload-success.hbs"
    }
  });
  _exports.default = _default;
});