define("shared-components/templates/components/card-withdraw-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UKgIJJKs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"iframe\",true],[11,\"class\",[29,[\"card card-payment \",[28,\"unless\",[[28,\"and\",[[24,[\"elevatedToken\"]],[24,[\"paymentsUiLoaded\"]]],null],\"card-payment--loading\"],null]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/card-withdraw-card.hbs"
    }
  });
  _exports.default = _default;
});