define("shared-components/templates/components/no-us-banks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gGA8HNH1",
    "block": "{\"symbols\":[\"@message\",\"@walletDestination\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card_body card_body--empty grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wallet_icon\"],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"cell card_text card_text--empty\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"footer\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"tagName\",\"route\"],[\"btn btn--neutral\",\"button\",[23,2,[]]]],{\"statements\":[[1,[28,\"t\",[\"wallet.manage\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/no-us-banks.hbs"
    }
  });
  _exports.default = _default;
});