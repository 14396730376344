define("shared-components/templates/components/doc-upload-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "k+jVJBQ6",
    "block": "{\"symbols\":[\"@fileUrl\",\"@multiImage\",\"@file\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"type\"]],\"application/pdf\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"doc-upload_option-pdf-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"doc-upload_option-icon doc-upload_option-pdf \",[28,\"if\",[[23,2,[]],\"doc-upload_multi-image\"],null]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"doc-upload_option-pdf-name\"],[8],[1,[23,3,[\"name\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"id\",\"uploadImage\"],[11,\"src\",[23,1,[]]],[11,\"class\",[29,[\"doc-upload_image \",[28,\"if\",[[23,2,[]],\"doc-upload_multi-image\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/doc-upload-image.hbs"
    }
  });
  _exports.default = _default;
});