define("shared-components/utils/to-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = toPercent;
  function toPercent(numerator, denominator) {
    return numerator / denominator * 100;
  }
});