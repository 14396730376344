define("shared-components/components/material-input", ["exports", "ember-diff-attrs", "shared-components/templates/components/material-input"], function (_exports, _emberDiffAttrs, _materialInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _materialInput.default,
    classNames: ['fieldset'],
    classNameBindings: ['errorMessage:fieldset--error', '_empty:fieldset--empty', 'isFocused:fieldset--focus', 'inline:fieldset--inline', 'materialDesign:fieldset--material'],
    isFocused: false,
    _empty: Ember.computed.not('hasValue'),
    didReceiveAttrs: (0, _emberDiffAttrs.default)('errorMessage', function (changedAttrs) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      var focusOnRender = (!changedAttrs || changedAttrs.errorMessage) && this.errorMessage && this.focusOnError;
      Ember.set(this, '_focusOnRender', focusOnRender);
    }),
    didRender: function didRender() {
      if (this._focusOnRender) {
        Ember.set(this, '_focusOnRender', false);
        this.element.querySelector('input').focus();
      }
    },
    focusIn: function focusIn() {
      Ember.set(this, 'isFocused', true);
    },
    focusOut: function focusOut() {
      Ember.set(this, 'isFocused', false);
    }
  });
  _exports.default = _default;
});