define("shared-components/templates/components/doc-upload-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cPOywQYl",
    "block": "{\"symbols\":[\"option\",\"@noDocumentsComponent\",\"@onSelectOption\",\"@type\",\"@options\",\"&default\",\"@secureText\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"card_body doc-upload_options doc-upload_options--\",[23,4,[]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"doc-upload_option\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,3,[]],[23,1,[]]],null]]],[8],[0,\"\\n      \"],[7,\"span\",true],[11,\"class\",[29,[\"doc-upload_option-icon doc-upload_option-\",[23,1,[]]]]],[8],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"doc-upload_option-text\"],[8],[1,[28,\"t\",[[28,\"concat\",[\"docUpload.\",[23,4,[]],\".\",[23,1,[]]],null]],null],false],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"doc-upload_option-sub-text\"],[8],[1,[28,\"t\",[[28,\"concat\",[\"docUpload.\",[23,4,[]],\".\",[23,1,[]],\"-explain\"],null]],[[\"default\"],[\"empty-string\"]]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[14,6],[0,\"\\n\\n\"],[7,\"p\",false],[12,\"class\",\"doc-upload_no-documents\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"showNoDocuments\"]]],null],true],null]]],[8],[1,[28,\"t\",[\"docUpload.unable\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showNoDocuments\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,2,[]]],[[\"onClose\"],[[28,\"fn\",[[28,\"mut\",[[23,0,[\"showNoDocuments\"]]],null],false],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"footer\",true],[10,\"class\",\"doc-upload_secure-text\"],[8],[0,\"\\n  \"],[1,[23,7,[]],false],[0,\" \"],[7,\"a\",true],[10,\"href\",\"https://www.nadex.com/legal\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[1,[28,\"t\",[\"docUpload.privacy\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/doc-upload-options.hbs"
    }
  });
  _exports.default = _default;
});