define("shared-components/templates/components/doughnut-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "S0aLQfkl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"100%\"],[10,\"height\",\"100%\"],[10,\"viewBox\",\"0 0 42 42\"],[10,\"class\",\"doughnut\"],[8],[0,\"\\n  \"],[7,\"circle\",true],[10,\"class\",\"doughnut-hole\"],[10,\"cx\",\"21\"],[10,\"cy\",\"21\"],[10,\"r\",\"15.91549430918954\"],[10,\"fill\",\"none\"],[8],[9],[0,\"\\n  \"],[7,\"circle\",true],[10,\"class\",\"doughnut-segment\"],[10,\"cx\",\"21\"],[10,\"cy\",\"21\"],[10,\"r\",\"15.91549430918954\"],[10,\"fill\",\"transparent\"],[10,\"stroke\",\"#2eaae1\"],[10,\"stroke-width\",\"3\"],[11,\"stroke-dasharray\",[22,\"doughnutRatioAvailable\"]],[10,\"stroke-dashoffset\",\"75\"],[8],[9],[0,\"\\n  \"],[7,\"circle\",true],[10,\"class\",\"doughnut-segment\"],[10,\"cx\",\"21\"],[10,\"cy\",\"21\"],[10,\"r\",\"15.91549430918954\"],[10,\"fill\",\"transparent\"],[10,\"stroke\",\"#F5C817\"],[10,\"stroke-width\",\"3\"],[11,\"stroke-dasharray\",[22,\"doughnutRatioReserved\"]],[11,\"stroke-dashoffset\",[22,\"doughnutRatioReservedOffset\"]],[8],[9],[0,\"\\n  \"],[7,\"circle\",true],[10,\"class\",\"doughnut-segment\"],[10,\"cx\",\"21\"],[10,\"cy\",\"21\"],[10,\"r\",\"15.91549430918954\"],[10,\"fill\",\"transparent\"],[10,\"stroke\",\"#143a63\"],[10,\"stroke-width\",\"3\"],[11,\"stroke-dasharray\",[22,\"doughnutRatioPositions\"]],[11,\"stroke-dashoffset\",[22,\"doughnutRatioPositionsOffset\"]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/doughnut-chart.hbs"
    }
  });
  _exports.default = _default;
});