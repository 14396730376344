define("shared-components/utils/is-inactive-member-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInactiveMemberStatus = isInactiveMemberStatus;
  function isInactiveMemberStatus(memberStatus) {
    return memberStatus === 'INACTIVE_MEMBER';
  }
});