define('ismobilejs/services/is-mobile', ['exports', 'ismobilejs'], function (exports, _ismobilejs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var queries = [];
      if (Ember.get(this, 'fastboot.isFastBoot')) {
        var headers = Ember.get(this, 'fastboot.request.headers');
        var userAgent = Ember.get(headers, 'headers.user-agent');

        // isMobile tries to fetch `navigator` if we give it a blank user agent.
        if (Ember.isBlank(userAgent)) {
          return;
        }

        userAgent = userAgent[0];

        // Call with the current context to avoid leaking the node global space!
        queries = _ismobilejs.default.call(this, userAgent);
      } else {
        queries = _ismobilejs.default;
      }

      for (var media in queries) {
        Ember.set(this, media, queries[media]);
      }
    }
  });
});