define("shared-components/templates/components/reauthentication-in-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vGBn5urM",
    "block": "{\"symbols\":[\"@message\",\"@error\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"reauthentication-screen\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"role\",\"button\"],[8],[1,[28,\"t\",[\"payments.auth0-try-again\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"reauthentication-screen_spinner\"],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"reauthentication-screen_message\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared-components/templates/components/reauthentication-in-progress.hbs"
    }
  });
  _exports.default = _default;
});