define("my-account/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('profile', {
      path: '/'
    }, function () {
      this.route('address');
      this.route('change-password');
      this.route('contact');
      this.route('contact-details');
      this.route('deposit', function () {
        this.route('card', function () {
          this.route('add');
        });
        this.route('bank-transfer');
        this.route('wire-transfer', function () {
          this.route('location', {
            path: '/:location'
          });
        });
      });
      this.route('feedback');
      this.route('history', function () {
        this.route('results', {
          path: '/:type'
        });
      });
      this.route('overview');
      this.route('replenish-balance');
      this.route('statement');
      this.route('tax-statement');
      this.route('switch-account');
      this.route('wallet', function () {
        /* eslint-disable ember/routes-segments-snake-case */
        this.route('card', {
          path: '/:cardReference'
        });
        this.route('bank', function () {
          this.route('add', function () {
            this.route('confirm');
          });
          this.route('account', {
            path: '/:bankAccountId'
          });
        });
      });
      this.route('withdraw', function () {
        this.route('ach');
        this.route('wire');
        this.route('card');
      });
    });
    this.route('verification', function () {
      this.route('address', function () {
        this.route('upload', {
          path: '/:type'
        }, function () {
          this.route('review');
        });
      });
      this.route('identity', function () {
        this.route('upload', {
          path: '/:type'
        }, function () {
          this.route('review');
        });
      });
      this.route('progress', {
        path: '/progress/:type'
      });
      this.route('ssn', function () {
        this.route('upload', {
          path: '/:type'
        }, function () {
          this.route('review');
        });
      });
    });
  });
  _exports.default = _default;
});